import axios from 'axios';

export default async (supplier, email, password) => {
  const data = { supplier, email, password };
  const client = axios.create();
  const result = await client.post(`${process.env.VUE_APP_API_ENDPOINT}/public/users/migrate`, data).catch((e) => {
    if (e?.response?.status !== 404) {
      throw new Error(e?.response?.data?.message ?? e.message);
    }
    return { data: undefined };
  });
  return result.data;
};
