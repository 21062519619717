<script>
import { mapActions } from 'vuex';
import findUserId from '@/api/user/findUserId';
import migrateUser from '@/api/user/migrateUser';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: undefined,
      submitting: false,
      success: undefined,
    };
  },
  methods: {
    ...mapActions('auth', ['userLoggedIn']),
    async submit() {
      try {
        this.submitting = true;
        const subscriber = String(this.$i18n.t('site.subscriberId'));
        const supplier = String(this.$i18n.t('site.companyTitle'));
        let username = await findUserId(subscriber, this.email);
        if (!username) {
          username = await migrateUser(supplier, this.email, this.password);
        }
        username = username ?? this.email;
        await this.$auth.signIn(username ?? this.email.trim().toLowerCase(), this.password, { subscriber });
        await this.userLoggedIn(this.$auth);
        setTimeout(() => this.$router.push({ path: '/dashboard' }).catch(() => undefined), 1000);
        this.success = true;
      } catch (e) {
        this.error = e.message;
        this.success = false;
      }
      this.submitting = false;
    },
  },
  async mounted() {
    if (await this.$auth.currentAuthenticatedUser().catch(() => undefined)) {
      await this.$router.push({ path: '/dashboard' });
    }
  },
};
</script>

<template>
  <div class="container">
    <div class="row p-2">
      <div class="col-12 border-bottom text-center">
        <h2 class="title">Sign in to your account</h2>
      </div>
    </div>
    <div>
      <div v-if="success" class="row p-2">
        <div class="col-12 text-success">
          Successfully logged in. You should be redirected shortly; if not click
          <router-link to="/auth/login">here.</router-link>
        </div>
      </div>
      <div v-else>
        <div class="row p-2">
          <div class="col-12">
            <p>Please enter your email and password below to sign in to your account.</p>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit()">
          <ValidationProvider ref="email" v-slot="{ errors }" name="email" rules="required|email">
            <div class="row p-2">
              <div class="col-lg-3 col-xs-12">
                <label for="email">Email:</label>
              </div>
              <div class="col-lg-9 col-xs-12">
                <b-form-input id="email" v-model="email" name="email" type="email" />
              </div>
            </div>
            <div v-for="(error, index) in errors" v-bind:key="index" class="row p-2">
              <div class="col-12 text-danger">
                {{ error }}
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider ref="password" v-slot="{ errors }" name="password" rules="required">
            <div class="row p-2">
              <div class="col-lg-3 col-xs-12">
                <label for="password">Password:</label>
              </div>
              <div class="col-lg-9 col-xs-12">
                <b-form-input id="password" v-model="password" name="password" type="password" />
              </div>
            </div>
            <div v-for="(error, index) in errors" v-bind:key="index" class="row p-2">
              <div class="col-12 text-danger">
                {{ error }}
              </div>
            </div>
          </ValidationProvider>
          <div v-if="success === false" class="row p-2">
            <div class="col-12 text-danger">{{ error }}</div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-right">
              <router-link to="/auth/request-password-reset">Forgot Password?</router-link>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12">
              <button :disabled="invalid || submitting" class="btn bg-primary col-12" type="submit" @click="submit">
                Sign In <i v-if="submitting" class="icon-spinner11 mr-2 spinner"></i>
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
h2.title {
  @media only screen and (max-width: 1121px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 561px) {
    font-size: 22px;
  }
}
</style>
