import axios from 'axios';

export default async (subscriber, email) => {
  const client = axios.create();
  const result = await client
    .get(`${process.env.VUE_APP_API_ENDPOINT}/public/users/${subscriber}/${encodeURIComponent(email)}`)
    .catch(() => ({
      data: undefined,
    }));
  return result.data;
};
